@charset "utf-8";

$accent-color: #020202;
$nav-background-color: #020202;

@import "so-simple";

.book-cover {
  float: left;
  margin-right: 1em;
  max-height: 200px;
}
